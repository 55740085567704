<template>
	<div class="study-card">
		<div v-if="showDetails" class="study-details">
			<template v-if="isLoading">
				<svg-icon icon="spinner" pulse fixed />
			</template>
			<template v-else>
				<div class="status-icon">
					<svg-icon :icon="icon" fixed :pulse="icon === 'spinner'" :class="iconClass" />
				</div>
				<div>
					<template v-if="saleAttachmentDescription">
						<p>
							<strong>{{ saleAttachmentDescription }}</strong>
						</p>
					</template>
					<template v-else>
						<p v-if="patientName">
							<strong>{{ patientName }}</strong>
						</p>
						<p v-if="patientId">ID: {{ patientId }}</p>
					</template>
					<p v-if="uploads && uploads.length === 1 && uploads[0].isAttachment">
						{{ uploads[0].file.name }}
					</p>
					<p class="study-date">
						{{ studyDateTime | localizeDate({ forceUTC: false, showTime: false }) }}
					</p>
					<p v-if="imageData">{{ imageAndAttachmentCounts }}</p>
					<p v-if="uploads && uploads.length">
						{{ uploads.length }} file{{ uploads.length > 1 ? 's' : '' }} ({{
							totalSize | fileSize
						}})
						<span v-if="study.modality" class="modality">{{ modality }}</span>
					</p>
				</div>
			</template>
		</div>
		<!-- Slot content -->
		<slot></slot>
		<!-- Progressbar -->
		<div v-if="progressPercent != null && progressPercent < 100" class="study-progress">
			<p class="progress-status">{{ progressStatus }} &hellip;</p>
			<progress-bar :width="progressPercent" />
			{{ progressPercent }}%
		</div>
		<!-- Close button -->
		<ast-button
			v-if="showCloseButton && !isLoading"
			class="close"
			@click.native.stop="$emit('close')"
		>
			<svg-icon icon="close" />
		</ast-button>
	</div>
</template>

<script>
import AstButton from '@components/Button'
import ProgressBar from '@components/ProgressBar'
import { showAlert } from '@dialogs/MessageDlg.vue'

export default {
	name: 'StudyCard',
	components: {
		AstButton,
		ProgressBar,
	},
	props: {
		icon: {
			type: String,
			default: 'check-circle',
		},
		iconClass: {
			type: String,
			default: 'is-success',
		},
		uploads: {
			type: Array,
			default: null,
		},
		showCloseButton: {
			type: Boolean,
			default: true,
		},
		showDetails: {
			type: Boolean,
			default: true,
		},
		study: {
			type: Object,
			required: true,
		},
		progressPercent: {
			type: Number,
			default: null,
		},
		progressStatus: {
			type: String,
			default: null,
		},
	},
	data() {
		return {
			isLoading: false,
			patientName: null,
			patientId: null,
			studyDateTime: null,
			imageCount: null,
			imageData: undefined,
		}
	},
	computed: {
		totalSize() {
			if (!this.uploads) return
			const sizes = this.uploads.map(upload => upload.file.size)
			return sizes.reduce((a, b) => a + b, 0)
		},
		modality() {
			// if study.modality is actually a modalityId, convert it to the modality name
			const modalityById = this.$store.state.static.modalities.find(
				m => m.id === this.study.modality
			)
			return modalityById ? modalityById.name : this.study.modality
		},
		imageAndAttachmentCounts() {
			if (!this.imageData) return
			const counts = []
			const attachments = this.imageData.attachments && this.imageData.attachments.length
			if (this.imageCount)
				counts.push(`${this.imageCount} image` + `${this.imageCount === 1 ? '' : 's'}`)
			if (attachments) counts.push(`${attachments} attachment` + `${attachments === 1 ? '' : 's'}`)
			return counts.join(' and ')
		},
		saleAttachmentDescription() {
			if (this.patientId !== this.patientName) return null
			if (this.uploads && this.uploads.length === 1) return this.uploads[0].description
			const attachments = this.imageData && this.imageData.attachments
			if (attachments && attachments.length)
				return attachments[0].description || attachments[0].friendlyName
			return null
		},
	},
	watch: {
		study: {
			handler(study) {
				if (study.patientName || study.patientId) {
					this.patientName = study.patientName
					this.patientId = study.patientId
					this.studyDateTime = study.studyDateTime
				}
			},
			deep: true,
			immediate: true,
		},
		'study.studyId': {
			handler(newVal, oldVal) {
				if (newVal && newVal !== oldVal) this.getStudy()
			},
			deep: true,
			immediate: true,
		},
	},
	methods: {
		async getStudy() {
			this.isLoading = true
			try {
				const { studies, deletedStudies } = await this.$api.viewer.getStudy(
					{ ids: [this.study.studyId] },
					false
				)
				if (studies && studies.length) {
					const study = studies[0]
					const attachments = study.imageData.attachments || []
					this.patientName = study.patientName
					this.patientId = study.patientId
					this.imageData = study.imageData
					this.imageCount = study.imageCount - attachments.length
					this.studyDateTime = study.studyDateTime
					this.$emit('get-study', study)
				} else if (deletedStudies && deletedStudies.length) {
					this.$emit('force-close')
					showAlert(
						'The selected study was deleted from the source account and is no longer available.'
					)
				} else {
					this.$emit('force-close')
					showAlert('The selected study is no longer available.')
				}
			} finally {
				this.isLoading = false
			}
		},
	},
}
</script>

<style lang="scss" scoped>
@import '~@styles/_vars.scss';
.study-card {
	background: var(--secondary-bg);
	border: 1px solid var(--secondary-border);
	padding: 15px 30px 15px 15px;
	position: relative;
	max-width: 1000px;
	.study-details {
		display: flex;
		align-items: center;
		user-select: none;
		p {
			word-break: break-word;
		}
		p.file-count {
			padding-top: 0.5em;
		}
		strong {
			font-weight: 400;
		}
		.status-icon .icon {
			font-size: 1.25em;
			margin-right: 15px;
		}
	}
	.upload-status {
		margin-top: 15px;
		min-height: 2em;
	}
	.study-date {
		font-size: 0.75em;
	}
	.modality {
		font-size: 0.7em;
		padding: 0.125em 0.25em;
		background: var(--tertiary-bg);
	}
	button.close {
		position: absolute;
		top: 0;
		right: 0;
		padding: 15px;
		background: transparent;
		border: 0;
	}
	.study-progress {
		padding: 15px 15px 0 15px;
		font-size: 0.9em;
		text-align: center;
		.progress-status {
			font-style: italic;
			margin-bottom: 4px;
		}
	}
}
</style>
