<template>
	<div>
		<div v-if="requestConsultant" ref="imageDrop" class="upload-section">
			<p v-if="requestConsultant.type === 'ImageOnly'" class="image-only-message">
				<svg-icon icon="info-circle" class="is-info" />
				{{ requestConsultant.name }} only accepts images. You cannot submit any report information.
			</p>
			<template v-if="ids.length">
				<h3>These studies will be sent to {{ requestConsultant.name.replace(/\.$/, '') }}.</h3>
				<transition-group name="expand">
					<study-card
						v-for="studyId in ids"
						:key="studyId"
						:study="{ studyId }"
						class="study-card"
						@force-close="removeExistingStudy({ studyId, requireConfirmation: false })"
						@close="removeExistingStudy({ studyId })"
						@get-study="addStudyMetadata"
					/>
				</transition-group>
				<p style="margin-bottom: 16px;">
					<ast-button type="success" @click.native="selectFromExisting">
						<span class="plus">+</span>
						Add a previously uploaded study
					</ast-button>
				</p>
				<h3>Add any additional images you would like to send.</h3>
			</template>
			<template v-else>
				<h3 v-if="!ids.length">
					Add
					{{ requestConsultant.isImageOptional ? 'any' : 'the' }}
					images you would like to send to {{ requestConsultant.name.replace(/\.$/, '') }}.
				</h3>
				<p style="margin-bottom: 16px;">
					<ast-button type="success" @click.native="selectFromExisting">
						<span class="plus">+</span>
						Add a previously uploaded study
					</ast-button>
				</p>
			</template>
			<upload-form
				class="upload-form"
				context="teleconsultation-request"
				:drop-target="imageDrop"
				:allow-dicoms="true"
				:allow-jpegs="true"
				:allow-attachments="false"
			/>
			<div v-if="images.length || excludedImages.length" class="upload-items">
				<!-- Excluded Files -->
				<transition name="expand">
					<ol v-if="excludedImages.length" class="excluded-files">
						<li is="UploadItemFile" v-for="(file, i) in excludedImages" :key="i" :upload="file" />
						<!-- Clear excluded files button -->
						<ast-button class="close" @click.native="clearExcludedFiles('teleconsultation-request')">
							<svg-icon icon="close" />
						</ast-button>
					</ol>
				</transition>
				<!-- Studies -->
				<transition-group name="expand">
					<upload-item-batch
						v-for="batchId in imageBatchIds"
						:key="batchId ? batchId : 'jpeg-form'"
						:uploads="getUploadsInBatch(batchId)"
						@remove-batch="removeImages"
						@study-complete="addUploadedBatch"
					/>
				</transition-group>
			</div>
		</div>

		<div v-if="requestConsultant && requestConsultant.type !== 'ImageOnly'" ref="attachmentDrop" class="upload-section">
			<h3>Add any supplementary files.</h3>
			<upload-form
				class="upload-form"
				context="teleconsultation-request"
				:drop-target="attachmentDrop"
				:allow-dicoms="true"
				:allow-jpegs="false"
				:allow-attachments="true"
			/>
			<div v-if="attachments.length || excludedAttachments.length" class="upload-items">
				<!-- Excluded Files -->
				<transition name="expand">
					<ol v-if="excludedAttachments.length" class="excluded-files">
						<li is="UploadItemFile" v-for="(file, i) in excludedAttachments" :key="i" :upload="file" />
						<!-- Clear excluded files button -->
						<ast-button class="close" @click.native="clearExcludedFiles('teleconsultation-request')">
							<svg-icon icon="close" />
						</ast-button>
					</ol>
				</transition>
				<!-- Attachment Study Metadata Form -->
				<transition name="expand">
					<div v-if="showAttachmentStudyForm" class="attachment-study-form">
						<upload-study-form
							context="teleconsultation-request"
							:hide-drop-hint="true"
							:is-attachment="true"
							:study="lastStudyMetadata"
						/>
					</div>
				</transition>
				<!-- Attachments -->
				<transition-group name="expand">
					<upload-item-batch
						v-for="batchId in attachmentBatchIds"
						:key="batchId ? batchId : 'attachment-form'"
						:uploads="getUploadsInBatch(batchId)"
						only-show-files
						@remove-file="removeFile"
						@study-complete="addUploadedBatch"
					/>
				</transition-group>
			</div>
		</div>
	</div>
</template>

<script>
import AstButton from '@components/Button.vue'
import UploadForm from '@components/UploadForm.vue'
import StudyCard from '@components/StudyCard.vue'
import { openUserStudiesDlg } from '@dialogs/UserStudiesDlg.vue'
import { showConfirm } from '@dialogs/ConfirmDlg.vue'
import workflow from '@services/workflow'
import { uploadData } from '@services/uploads'

const UPLOAD_CONTEXT = 'teleconsultation-request'

export default {
	name: 'TeleconsultationRequestUploadImages',
	components: {
		AstButton,
		UploadForm,
		StudyCard,
		UploadItemFile: () => import(/* webpackChunkName: "componentUploadItemFile" */ '@components/UploadItemFile'),
		UploadItemBatch: () => import(/* webpackChunkName: "componentUploadItemBatch" */ '@components/UploadItemBatch'),
		UploadStudyForm: () => import(/* webpackChunkName: "componentUploadStudyForm" */ '@components/UploadStudyForm'),
	},
	props: {
		ids: {
			type: Array,
			required: true,
		},
		consultantId: {
			type: String,
			required: true,
		},
	},
	data() {
		return {
			attachmentDrop: undefined,
			imageDrop: undefined,
			studyMetadata: [],
			uploadedStudyIds: [],
			uploadedBatchIds: [],
			uploadData,
		}
	},
	computed: {
		pendingJpegUploadCount() {
			return this.uploadData.getPendingUploads(UPLOAD_CONTEXT).filter(u => u.isImage).length
		},
		pendingAttachmentUploadCount() {
			return this.uploadData.getPendingUploads(UPLOAD_CONTEXT).filter(u => u.isAttachment).length
		},
		isUploadError() {
			return this.uploadData.isUploadError(UPLOAD_CONTEXT)
		},
		excludedFiles() {
			return this.uploadData.excludedFiles.filter(upload => upload.context === UPLOAD_CONTEXT)
		},
		uploads() {
			return this.uploadData.uploads.filter(upload => upload.context === UPLOAD_CONTEXT)
		},
		requestConsultant() {
			return workflow.consultant
		},
		excludedImages() {
			return this.excludedFiles.filter(upload => !upload.isAttachment)
		},
		excludedAttachments() {
			return this.excludedFiles.filter(upload => upload.isAttachment)
		},
		images() {
			return this.uploads.filter(upload => !upload.isAttachment)
		},
		attachments() {
			return this.uploads.filter(upload => upload.isAttachment)
		},
		imageBatchIds() {
			return [...new Set(this.images.map(upload => upload.batchId))]
		},
		attachmentBatchIds() {
			return [...new Set(this.attachments.map(upload => upload.batchId))]
		},
		batchIds() {
			// excludes any blank/null batchIds to prevent extra study metadata forms
			return [...new Set(this.uploads.map(upload => upload.batchId).filter(Boolean))]
		},
		studyIds() {
			const studyIds = [...this.ids, ...this.uploadedStudyIds]
			return [...new Set(studyIds)] // dedupe
		},
		lastStudyMetadata() {
			if (!this.studyMetadata.length) return
			return this.studyMetadata[this.studyMetadata.length - 1]
		},
		showAttachmentStudyForm() {
			return (
				this.pendingAttachmentUploadCount && // have pending attachments
				!this.pendingJpegUploadCount && // and not showing jpeg form
				// and cannot copy metadata from existing study (hides form while getting batchId)
				!this.uploads.some(upload => !!upload.studyInstanceUid)
			)
		},
		canGoNext() {
			if (!this.requestConsultant) return false
			// make sure no uploads are waiting on metadata from the user
			if (this.pendingAttachmentUploadCount || this.pendingJpegUploadCount) return false
			// make sure user has added required images
			const hasUploads = this.uploads.some(upload => upload.studyInstanceUid)
			const hasRequiredImages =
				this.requestConsultant.isImageOptional || this.images.some(upload => upload.studyInstanceUid)
			if (!this.ids.length && !(hasUploads && hasRequiredImages)) return false
			// make sure uploads are uploaded, processed, and error-free
			if (uploadData.isUploading(UPLOAD_CONTEXT)) return false
			if (this.batchIds.length !== this.uploadedBatchIds.length) return false
			if (this.isUploadError) return false
			if (!this.studyIds.length) return false
			return true
		},
	},
	watch: {
		canGoNext: {
			handler(canGoNext) {
				this.updateNextRoute()
				workflow.canGoNext = canGoNext
			},
			immediate: true,
		},
		studyIds: {
			// ensure any added studyIds get passed to next route
			handler() {
				this.updateNextRoute()
			},
			immediate: true,
		},
	},
	mounted() {
		this.attachmentDrop = this.$refs.attachmentDrop
		this.imageDrop = this.$refs.imageDrop
	},
	methods: {
		clearExcludedFiles(context) {
			uploadData.CLEAR_UPLOAD_EXCLUDED_FILES(context)
		},
		getUploadsInBatch(batchId) {
			return this.uploads.filter(upload => upload.batchId === batchId || (!upload.batchId && !batchId))
		},
		addUploadedBatch({ batchId, studyId, imageId, reportTemplateImageViewId }) {
			if (batchId && !this.uploadedBatchIds.includes(batchId)) {
				this.uploadedBatchIds.push(batchId)
			}
			if (studyId && !this.uploadedStudyIds.includes(studyId)) {
				this.uploadedStudyIds.push(studyId)
			}
		},
		addStudyMetadata(study) {
			if (this.studyMetadata.some(metadata => metadata.studyId === study.studyId)) return
			this.studyMetadata.push(study)
		},
		async removeExistingStudy({ studyId, requireConfirmation = true }) {
			const warning = 'Are you sure you want to remove this study?'
			if (requireConfirmation && !(await showConfirm(warning))) return
			this.studyMetadata = this.studyMetadata.filter(metadata => metadata.studyId !== studyId)
			const newIds = this.ids.filter(id => id !== studyId)
			this.$router.replace({
				query: {
					...this.$route.query,
					studyId: newIds.join(','),
				},
			})
		},
		async removeImages({ batchId, studyInstanceUid, studyId }) {
			const warning = 'Are you sure you want to remove this upload?'
			if (!(await showConfirm(warning))) return
			const imagesInBatch = this.images.filter(i => i.batchId === batchId)
			imagesInBatch.forEach(u => this.removeFile({ ...u, batchId, studyInstanceUid, studyId }, false))
		},
		async removeFile({ batchId, isAttachment, sopInstanceUid, studyInstanceUid, studyId }, showWarning = true) {
			const warning = 'Are you sure you want to remove this upload?'
			if (showWarning && !(await showConfirm(warning))) return
			uploadData.stopUploads({ sopInstanceUid })
			if (batchId && !this.batchIds.includes(batchId))
				this.uploadedBatchIds = this.uploadedBatchIds.filter(i => i !== batchId)
			if (studyId && !this.uploads.some(a => a.studyInstanceUid === studyInstanceUid)) {
				this.uploadedStudyIds = this.uploadedStudyIds.filter(i => i !== studyId)
			}
			// delete newly uploaded attachments from the study
			if (isAttachment)
				this.$api.study.deleteSeries({
					sopInstanceUid,
					studyId,
				})
		},
		async selectFromExisting() {
			const study = await openUserStudiesDlg()
			if (!study || this.ids.includes(study.studyId)) return
			this.$router.replace({
				query: {
					...this.$route.query,
					studyId: [...this.ids, study.studyId].join(','),
				},
			})
		},
		updateNextRoute() {
			if (!this.requestConsultant) return
			let nextRoute
			// route to either full report form or image-only submission form
			if (this.requestConsultant.type === 'ImageOnly') nextRoute = 'request-submit-image-only'
			else nextRoute = 'request-submit-report'
			// add final list of studyIds to query
			workflow.nextRoute = {
				name: nextRoute,
				query: { ...this.$route.query, studyId: this.studyIds.join(',') },
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.image-only-message {
	background: var(--card-bg);
	border-left: 3px solid var(--icon-info);
	margin-bottom: 20px;
	padding: 8px;
}
h3 {
	margin-bottom: 20px;
}
.plus {
	font-size: 1.4em;
	padding-right: 8px;
	margin: -0.2em 0;
}
.study-card {
	margin: 15px 0;
}
.upload-section {
	display: flex;
	flex-direction: column;
	max-width: 800px;
}
.upload-section + .upload-section {
	padding-top: 40px;
}
.upload-form {
	min-height: 200px;
}
.upload-progress {
	text-align: center;
	font-size: 1.5em;
	padding-bottom: 1em;
	font-weight: 400;
	.upload-percent {
		text-align: center;
		font-weight: 300;
	}
}
.upload-items {
	min-height: 0;
	padding-top: 30px;

	ol {
		list-style-type: none;
		position: relative;
		button.close {
			position: absolute;
			top: 0;
			right: 0;
			padding: 15px;
			background: transparent;
			border: 0;
		}
	}
}
.attachment-study-form {
	background: var(--secondary-bg);
	border: 1px solid var(--secondary-border);
	margin: 15px 0;
	max-width: 1000px;
	padding: 15px 30px 15px 15px;
}
</style>
