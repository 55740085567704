import { render, staticRenderFns } from "./StudyCard.vue?vue&type=template&id=b88f63a8&scoped=true"
import script from "./StudyCard.vue?vue&type=script&lang=js"
export * from "./StudyCard.vue?vue&type=script&lang=js"
import style0 from "./StudyCard.vue?vue&type=style&index=0&id=b88f63a8&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b88f63a8",
  null
  
)

export default component.exports