<template>
	<div class="page-wrapper">
		<div class="list-scroll">
			<user-study-table @select-study="onSendStudy" />
		</div>
	</div>
</template>

<script>
import UserStudyTable from '@components/UserStudiesTable.vue'
import workflow from '@services/workflow'

export default {
	components: {
		UserStudyTable,
	},
	methods: {
		onSendStudy({ studyId }) {
			this.$router.push({
				name: 'request-consultants',
				query: { studyId },
			})
		},
	},
}
</script>
